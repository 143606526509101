<template>
    <div class="greenchannel-avtivated">
        <img src="./img/actived_icon.png" >
        <div class="desc">您已激活成功！</div>
    </div>
</template>

<script>
import { shareCtrl } from '@/common/js/mmcModule';
export default {
    name: 'WithdrawApplied',
    created() {
        shareCtrl.init();
    },
    methods: {}
}
</script>

<style lang='less' scoped>
.greenchannel-avtivated {
    padding: 0 20px;
    img {
        width: 200px;
        margin: 50px auto 10px;
        display: block;
    }
    .desc {
        font-size: 17px;
        color: #333333;
        text-align: center;
    }
}
</style>
